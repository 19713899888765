<template>
  <div class="a-collapse" :class="{ open: open }">
    <div class="a-collapse-header" @click.prevent.stop="toggleCollapse">
      <font-awesome-icon :icon="open ? 'minus' : 'plus'" />
      <span class="title"><slot name="title"></slot></span>
    </div>
    <div class="a-collapse-body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid'

export default {
  props: {
    init: {
      type: Boolean,
      default: false
    },
    selected: {
      required: false
    }
  },
  data() {
    return {
      uuid: uuid.v1()
    }
  },
  methods: {
    toggleCollapse() {
      if (this.uuid == this.selected) {
        this.$emit('toggle', null)
      } else {
        this.$emit('toggle', this.uuid)
      }
    }
  },
  computed: {
    open: function() {
      return this.uuid === this.selected
    }
  },
  mounted() {
    if (this.init) {
      this.$emit('toggle', this.uuid)
    }
  }
}
</script>

<style lang="scss" scoped>
.a-collapse {
  padding: 5px 10px;
  background: #ececec;
  border: 1px solid #d5d5d5;
  margin-bottom: 5px;
  border-radius: 4px;
  > .a-collapse-header {
    cursor: pointer;
    color: #5e5e5e;
    user-select: none;
    > .title {
      margin: 10px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  > .a-collapse-body {
    overflow: hidden;
    max-height: 0;
    font-size: 12px;
    > div {
      margin-top: 5px;
    }
  }

  &.open {
    background: #f7f7f7;
    > .a-collapse-body {
      transition: max-height 0.5s ease;
      max-height: 1000px;
      padding: 10px 25px;
    }
  }
}
</style>
